import {postMessageToNativeApp, isWebViewBridgeAvailable} from './post-message';
import {defaultNativeAppResponse} from './post-message';

/*
	Types
*/
type HeaderMenuIconsType =
    | 'Notifications'
    | 'Menu'
    | 'Person'
    | 'Close'
    | 'Settings'
    | 'Reload';

export type MenuItemsType = {
    id: string;
    icon: HeaderMenuIconsType;
    badge?: string;
};

export type HeaderMenuItemsType = {
    setItems: (
        menuItems: Array<MenuItemsType>,
    ) => Promise<defaultNativeAppResponse>;
    setClickHandler: (handler: (id: string) => any) => void;
};

/*
	Methods
*/

/**
 * Object contains methods to set menu items in the app's header
 */
export const headerMenuItems: HeaderMenuItemsType = {
    setItems: (menuItems) => {
        if (isWebViewBridgeAvailable()) {
            return UpdateMenuItems(menuItems);
        } else {
            return Promise.reject({
                code: 503,
                reason: 'bridge is not available',
            });
        }
    },
    setClickHandler: (handler) => {
        if (isWebViewBridgeAvailable()) {
            (window as any).onOrtaBridgeHeaderMenuItemClick = handler;
        } else if (typeof window !== 'undefined') {
            console.log('--setHeaderMenuItemClickHandler-isUnknown');
            return Promise.reject({
                code: 503,
                reason: 'bridge is not available',
            });
        }
    },
};

const UpdateMenuItems = (
    menuItems: Array<MenuItemsType>,
): Promise<defaultNativeAppResponse> =>
    postMessageToNativeApp({
        type: 'SET_HEADER_MENU_ITEMS',
        payload: menuItems,
    });

export const close = (): Promise<defaultNativeAppResponse> =>
    postMessageToNativeApp({
        type: 'CLOSE',
    });

/**
 * Method for managing URLs using device instead of Orta miniapp. Orta mobile client will delegate the decision to the device to handle the provided URL.
 * @param url - string URL
 * @returns a promise, which can either resolve or reject.
 */
export const openUrl = (url: string): Promise<defaultNativeAppResponse> =>
    postMessageToNativeApp(
        {
            type: 'OPEN_URL',
            payload: url,
        },
        500,
    );

export const pwa = (): Promise<defaultNativeAppResponse> =>
    postMessageToNativeApp(
        {
            type: 'PWA',
        },
        1000,
    );
