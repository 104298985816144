import {
	postMessageToNativeApp,
	isWebViewBridgeAvailable,
} from './post-message';
import { defaultNativeAppResponse } from './post-message';

/*
	Types
*/
type setCustomBackArrowOnClickHandlerType = (handler: () => any) => void;
type setTabActiveHandlerType = (handler: (appId: string) => any) => Promise<defaultNativeAppResponse>;
type setCustomBackArrowModeType = (mode: boolean) => Promise<defaultNativeAppResponse>
type setCustomBackArrowVisibleType = (visible: boolean) => Promise<defaultNativeAppResponse>

export type backArrowVisible = Readonly<{
	visible: boolean;
}>;
export type backArrowType = {
	setMode: setCustomBackArrowModeType;
	setOnClickHandler: setCustomBackArrowOnClickHandlerType;
	setVisible: setCustomBackArrowVisibleType;
}

/*
	Methods
*/

/**
 * Set the title in the header of the native app.
 */
export const setTitle = (title: string): Promise<defaultNativeAppResponse> => {
	if (isWebViewBridgeAvailable()) {
		return postMessageToNativeApp({
			type: 'SET_TITLE',
			payload: {
				title
			},
		});
	} else {
		if (typeof document !== 'undefined') {
			document.title = title;
		}
		return Promise.reject({
			code: 503,
			reason: "bridge is not available"
		});
	}
}

/**
 * Object containing methods to control custom back arrow mode behavior in the native app.
 */
export const backArrow: backArrowType = {
	/**
	 * Enable custom mode of the back arrow in the native app.
	 * @param mode - boolean
	 * @returns 
	 */
	setMode: (mode) => postMessageToNativeApp({
		type: 'CUSTOM_BACK_ARROW_MODE',
		payload: {
			mode
		}
	}),
	/**
	 * Set a callback function to be called when the back arrow in the native app is pressed.
	 * @param handler - Callback function
	 */
	setOnClickHandler: (handler) => {
		if (isWebViewBridgeAvailable()) {
			(window as any).onOrtaBridgeBackArrowClick = handler;
		} else if (typeof window !== 'undefined') {
			console.log('--setCustomBackArrowOnClickHandler-isUnknown');
		}
	},
	/**
	 * Control the visibility of the back arrow in the native app. To use this method, first enable the custom mode of the back arrow.
	 * @param visible - boolean
	 * @returns 
	 */
	setVisible: (visible) =>
		postMessageToNativeApp({
			type: 'SET_BACKARROWVISIBLE',
			payload: {
				visible,
			},
		})
};

/**
 * Set custom behavior for handling press events on the active bottom tab of the native app
 * @param handler - Callback function
 */
export const setTabActiveHandler: setTabActiveHandlerType = async (handler) => {
	return new Promise((resolve, reject) => {
		if (isWebViewBridgeAvailable()) {
			(window as any).onOrtaBridgeTabActive = handler;
			resolve({
				code: 200,
				message: 'OK'
			})
		} else if (typeof window !== 'undefined') {
			console.log('--setTabActiveHandler-isUnknown');
			reject({
				"code": 500,
				"reason": "unhandled error occured"
			})
		}
	})
};
